import trains_order_getBookingOrderPaymentInfo from '@/lib/data-service/trains/trains_order_getBookingOrderPaymentInfo'
import trains_order_orderPaymentStatus from '@/lib/data-service/trains/trains_order_orderPaymentStatus'
import PaymentMethodSelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/payment-method-selector/1.0.15/index.vue'
import Q from 'q';
export default {
  data () {
    return {
      orderNo: '',
      orderInfo: {},
      reload: 0,
      countDownTime: 0,
      countDownNumList: ['0', '00', '00', '00'],
      fromStationCode: '',
      toStationCode: '',
      time: '',
      type: 'reserve'
    }
  },
  components: {
    PaymentMethodSelector
  },
  created () {
    this.orderNo = this.$route.query.orderNo
    this.type = this.$route.query.type
    // this.fromStationCode = this.$route.query.fromStationCode
    // this.toStationCode = this.$route.query.toStationCode
    this.time = this.$route.query.time
    let reload = this.$route.query.reload
    if (reload) {
      this.reload = parseInt(reload)
    }
    console.log(this.reload)
  },
  async mounted () {
    if (this.reload === 1) {
      await this.getOrderStatus()
    }
    await this.getOrderInfo()
    this.__demo_PaymentMethodSelector_init()
    this.countDown(this.countDownTime)
  },
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    async getOrderStatus() {
      let res = await trains_order_orderPaymentStatus({orderNo: this.orderNo})
      if (res.ifTimeout === 2) {
        // 超时
        return
      }
      this.countDownTime = res.minutes * 60 + res.second
    },
    countDown(mm) {
      let time = mm * 1000
      let setTime = () => {
        let day = parseInt(time / (24 * 60 * 60 * 1000))
        let d = time - day * 24 * 60 * 60 * 1000
        let hour = parseInt(d / (1000 * 60 * 60))
        let h = d - hour * 60 * 60 * 1000
        let minute = parseInt(h / (60 * 1000))
        // let m = h - minute * 60 * 1000
        let second = parseInt((time - day * 60 * 60 * 24 * 1000 - hour * 60 * 60 * 1000 - minute * 60 * 1000) / 1000)
        this.countDownNumList = [day, hour > 9 ? hour : '0' + hour, minute > 9 ? minute : '0' + minute, second > 9 ? second : '0' + second]
        time = time - 1000
        if (time < 0) {
          clearInterval(interval)
        }
      };
      setTime()
      let interval = setInterval(() => {
        setTime()
      }, 1000)
    },
    async getOrderInfo() {
      let res = await trains_order_getBookingOrderPaymentInfo({orderNo: this.orderNo})
      this.orderInfo = res
      this.fromStationCode = res.fromStationCode
      this.toStationCode = res.toStationCode
      if (this.reload === 0) {
        this.countDownTime = res.seconds
      }
    },
    __demo_PaymentMethodSelector_init() {
      let _this = this
      this.$refs.aPaymentMethodSelector_000.init({
        situationParameter: {
          '000': {
            companyId: this.orderInfo.companyId,
            clubId: this.orderInfo.clubId,//中间社id
            orderNo: this.orderInfo.orderNo, //订单编号
            businessContent: this.orderInfo.businessContent, //不用管里面的值是什么，订单详情返回的businessContent,直接传入到这里  this.orderInfo.businessContent
            subject: this.orderInfo.productName, //
            orderAmount: this.orderInfo.orderAmount, //订单金额，用于计算可以用哪些支付方式支付
            count_down: {
              enable: true,//是否启用倒计时，默认值为true
              pay_remaining_seconds: this.countDownTime, //支付剩余时间，以秒为单位
            },
          },
        },

        //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
        before_pay_event_handler() {
          // alert(`不能够支付！原因：XXX`);
          // return Q.reject();
          return Q.resolve();
        },
        //支付成功事件处理方法
        pay_successful_event_handler() {
          console.log(`pay_successful_event_handler`);
          // 预定
          _this.$router.push({
            name: 'distributor-train-front-pay-success',
            query: {
              orderNo: _this.orderNo,
              status: 'success',
              fromStationCode: _this.orderInfo.fromStationCode,
              toStationCode: _this.orderInfo.toStationCode,
              time: _this.time,
              type: _this.type
            }
          })
        },
        //支付失败事件处理方法
        pay_failed_event_handler() {
          console.log(`pay_failed_event_handler`);
        },
      });
    },
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
